import http from '../../lib/http';
import { MATCHES_FETCHED_WITH_SUCCESS, MATCHES_INFO_FETCH_FAILED, MATCHES_INFO_FETCHED_WITH_SUCCESS, MATCHES_INFO_LOADING_STARTED, } from './actions-types';
export function LoadMatches() {
    return {
        type: MATCHES_INFO_LOADING_STARTED,
    };
}
export function MatchesInfoFetchedWithSuccess(weeks, info) {
    return {
        type: MATCHES_INFO_FETCHED_WITH_SUCCESS,
        weeks: weeks,
        info: info,
    };
}
export function MatchesFetchedWithSuccess(matches) {
    return {
        type: MATCHES_FETCHED_WITH_SUCCESS,
        matches: matches,
    };
}
export function MatchesFetchFailed() {
    return {
        type: MATCHES_INFO_FETCH_FAILED,
    };
}
export function FetchMatchesInfo(competitionFeed, seasonId) {
    return function (dispatch) {
        dispatch(LoadMatches());
        return http
            .get("matches/deadline-info", { competitionFeed: competitionFeed, seasonId: seasonId })
            .then(function (result) {
            dispatch(MatchesInfoFetchedWithSuccess(result.weeks, result.deadlineInfo));
        })
            .catch(function () {
            dispatch(MatchesFetchFailed());
        });
    };
}
export function FetchMatches() {
    return function (dispatch) {
        dispatch(LoadMatches());
        // return http
        //     .get(`matches`, { competitionFeed, seasonId })
        //     .then((result: any) => {
        //         dispatch(MatchesFetchedWithSuccess(result.matches));
        //     })
        //     .catch(() => {
        //         dispatch(MatchesFetchFailed());
        //     });
        // return axios({ method: 'get', url: `https://fanarena.s3.amazonaws.com/data_${competitionFeed}_${seasonId}_static.json` })
        //     .then((result: any) => {
        //         dispatch(MatchesFetchedWithSuccess(result.data.matches));
        //     })
        //     .catch((error: any) => {
        //         console.log(error);
        //         dispatch(MatchesFetchFailed());
        //     });
    };
}
export var MatchesActions;
(function (MatchesActions) {
    MatchesActions.loadMatches = LoadMatches;
    MatchesActions.matchesInfoFetchedWithSuccess = MatchesInfoFetchedWithSuccess;
    MatchesActions.matchesFetchedWithSuccess = MatchesFetchedWithSuccess;
    MatchesActions.matchesFetchFailed = MatchesFetchFailed;
    MatchesActions.fetchMatchesInfo = FetchMatchesInfo;
    MatchesActions.fetchMatches = FetchMatches;
})(MatchesActions || (MatchesActions = {}));
