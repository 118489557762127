var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import { ButtonStyle, MatchDaySelectorStyle } from './style';
var MaptchDaySelector = /** @class */ (function (_super) {
    __extends(MaptchDaySelector, _super);
    function MaptchDaySelector() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MaptchDaySelector.prototype.componentDidMount = function () {
        moment.locale('nl'); // Set Dutch locale
    };
    MaptchDaySelector.prototype.render = function () {
        var disablePrevious = this.props.min && this.props.day === this.props.min;
        var disableNext = this.props.max && this.props.day === this.props.max;
        return (React.createElement(MatchDaySelectorStyle, null,
            React.createElement(ButtonStyle, { type: "previous", className: disablePrevious ? 'disabled' : '', onClick: disablePrevious ? function () { return ({}); } : this.props.onPrev }),
            "Rit ",
            this.props.day,
            " - ",
            this.props.name,
            " - ",
            moment(this.props.date).local().format('dd DD/MM [om] H:mm'),
            React.createElement(ButtonStyle, { type: "next", className: disableNext ? 'disabled' : '', onClick: disableNext ? function () { return ({}); } : this.props.onNext })));
    };
    return MaptchDaySelector;
}(Component));
export default MaptchDaySelector;
