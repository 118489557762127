/* eslint-disable react/jsx-no-target-blank */
import {
    AbstractTeam,
    Block,
    Button,
    Calendar,
    Col,
    Helpers,
    LeaguesList,
    MatchDaySelector,
    Models,
    PlayerType,
    Redux,
    Row,
    Select,
    Substitutes,
    Team,
    Title,
    TitleType,
} from '@fanarena/shared';
// import teamBackground from '@fanarena/shared/assets/img/bg-cycling-shimano.png';
// import teamSponsor from '@fanarena/shared/assets/img/SSC_logo.jpg';
import { Icon, Spin } from 'antd'; // Import Spin for the loading indicator
import { pick } from 'lodash';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

export interface Props {
    match: any;
}

export interface State {
    notFound: boolean;
    navigateToLeagues: boolean;
    selectedLeagueId: number | null;
    isLoading: boolean; // New flag to track loading status
}

class TeamContainer extends Component<Models.AbstractTeamType & Props, State> {
    constructor(props: Models.AbstractTeamType & Props) {
        super(props);

        this.state = {
            notFound: false,
            navigateToLeagues: false,
            selectedLeagueId: null,
            isLoading: true, // Initially loading is true
        };
    }

    componentDidMount() {
        if (this.props.visibleWeekId) {
            this.getTeamInfo(this.props.visibleWeekId);
        }
    }

    componentDidUpdate(prevProps: Models.AbstractTeamType & Props) {
        if (
            this.props.visibleWeekId &&
            this.props.visibleWeekId !== prevProps.visibleWeekId
        ) {
            this.getTeamInfo(this.props.visibleWeekId);
        }

        if (
            prevProps.application.competition.competitionFeed !==
            this.props.application.competition.competitionFeed
        ) {
            this.setState({ notFound: true });
        }
    }

    getTeamInfo = (weekId: number) => {
        const teamId = parseInt(this.props.match.params.id);

        // Check if the team exists in the URL teams; if not, mark as not found.
        const urlTeam = this.props.user.teams.find(t => t.id === teamId);
        if (!urlTeam) {
            this.setState({ notFound: true, isLoading: false });
            return;
        }

        const playerProps = [
            'id',
            'name',
            'short',
            'positionId',
            'clubId',
            'value',
            'ban',
            'injury',
            'form',
            'forename',
            'surname',
        ];
        const selectionProps: any[] = [];

        // Start loading before the API call
        this.setState({ isLoading: true });

        Promise.all([Redux.Actions.Teams.get(teamId, weekId)])
            .then(([result]) => {
                const starting = result.players
                    .filter((player: any) => player.selection.positionId === 1)
                    .map((player: any) =>
                        Object.assign(
                            { inStarting: true },
                            pick(player, playerProps),
                            pick(player.selection, selectionProps)
                        )
                    );
                const bench = result.players
                    .filter((player: any) => player.selection.positionId === 0)
                    .map((player: any) =>
                        Object.assign(
                            { inStarting: false },
                            pick(player, playerProps),
                            pick(player.selection, selectionProps)
                        )
                    );
                const teamName = result.team.name;

                const captainId = result.players.find(
                    (player: any) => player.selection.captain === 1
                ).id;

                const budget = result.players.reduce(
                    (acc: any, player: any) => acc - player.value,
                    this.props.application.competition.budget
                );

                // Update team state in the parent (or Redux store)
                this.props.initTeamState(starting, bench, teamName, captainId, budget);
                // Hide the loading indicator when the data is loaded
                this.setState({ isLoading: false });
            })
            .catch(error => {
                this.setState({ notFound: true, isLoading: false });
            });
    };

    render() {
        // Display a full-page centered loading spinner while fetching data
        if (this.state.isLoading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Spin size="large" tip="Loading..." />
                </div>
            );
        }

        const {
            application,
            starting,
            bench,
            captainId,
            matches,
            gameData,
            user,
            visibleWeekId,
            initializedExternally,
            leagues,
        } = this.props;

        console.log(this.props);

        const startingByPositions = Helpers.startingListToPositionsList(
            starting,
            application.competition.lineupPositionRows
        );
        const startingPicked = starting.filter(player => !!player);
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];
        const notTeamOwner = team && team.userId && user.data && team.userId !== user.data.id;

        const leaguesWithNumbers = leagues.map((league, index) =>
            Object.assign({}, league, { recordNumber: index + 1 })
        );

        const teamActionButtonStyle = { marginTop: '15px', width: '100%', maxWidth: '630px' };
        const deadlineWeek = matches.info && matches.info.deadlineWeek;

        const match = gameData.data.matches.find(match => match.weekId === visibleWeekId);

        return (
            <React.Fragment>
                {(!initializedExternally && !match) && (
                    <Redirect
                        to={{
                            pathname: `/points/${team.id}`,
                        }}
                    />
                )}
                {(notTeamOwner || this.state.notFound) && (
                    <Redirect
                        to={{
                            //pathname: `/transfers/${team.id}`
                            pathname: `/home`,
                        }}
                    />
                )}
                {(visibleWeekId && deadlineWeek && (
                    <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                        <Col lg={24}>
                            <MatchDaySelector
                                day={visibleWeekId}
                                min={deadlineWeek}
                                name={(match && match.feedUrl) || ''}
                                date={(match?.date || '')}
                                onNext={(e: any) => this.props.onDayChange('next')}
                                onPrev={(e: any) => this.props.onDayChange('prev')}
                            />
                        </Col>
                    </Row>
                )) || null}
                {(initializedExternally && (
                    <Row gutter={[16, 16]}>
                        <Col lg={12} md={13} sm={12} xs={24}>
                            <Title type={TitleType.h3}>Opstelling</Title>
                            <Block style={{ textAlign: 'center' }}>
                                <Team
                                    widthRatio={12}
                                    heightRatio={12}
                                    bg={
                                        'https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/bg-lidl.jpg'
                                    }
                                    bgSponsor={''}
                                    selection={startingByPositions}
                                    assetsCdn={application.competition.assetsCdn}
                                    playerType={PlayerType.Cycling}
                                    onSwap={this.props.application.competition.benchSize > 0 ? this.props.onPlayerSwap : false}
                                    swapPlayerId={this.props.swapPlayerId}
                                    swappedFrom={this.props.swappedFrom}
                                    playerBadgeColor="#000"
                                    playerBadgeBgColor="#fff"
                                    playerPointsColor="#000"
                                    playerPointsBgColor="#83FF00"
                                />
                                {this.props.application.competition.benchSize > 0 && (
                                    <Substitutes
                                        selection={bench}
                                        title="De bus"
                                        bgColor="#3C3C3C"
                                        playerType={PlayerType.Cycling}
                                        assetsCdn={application.competition.assetsCdn}
                                        onSwap={this.props.onPlayerSwap}
                                        swapPlayerId={this.props.swapPlayerId}
                                        swappedFrom={this.props.swappedFrom}
                                        playerBadgeColor="#000"
                                        playerBadgeBgColor="#fff"
                                        playerPointsColor="#000"
                                        playerPointsBgColor="#fff"
                                    />
                                )}
                                <Block style={{ margin: 0, padding: '10px 0' }}>
                                    <a href="https://lidl.be" target="_blank">
                                        <img
                                            alt=""
                                            src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/banner_lidl.jpg"
                                        />
                                    </a>
                                </Block>
                            </Block>
                        </Col>
                        <Col lg={12} md={11} sm={12} xs={24}>
                            <Title type={TitleType.h3}>
                                {application.competition.competitionFeed === 'KLASF'
                                    ? 'Kopvrouw'
                                    : 'Kopman'}
                            </Title>
                            <Block>
                                <Select
                                    block
                                    keyProperty={'id'}
                                    value={captainId}
                                    notFoundContent={'Geen starters gevonden.'}
                                    textProperty={'name'}
                                    onSelect={this.props.onCaptainSelect}
                                    values={startingPicked}
                                    placeholder={
                                        startingPicked.length
                                            ? application.competition.competitionFeed === 'KLASF'
                                                ? 'Wijs een kopvrouw aan.'
                                                : 'Wijs een kopman aan.'
                                            : 'Selecteer eerst renners.'
                                    }
                                />
                                {team && visibleWeekId && (
                                    <Button
                                        onClick={(e: any) =>
                                            this.props.onTeamSelectionsUpdate(team.id, visibleWeekId)
                                        }
                                        style={teamActionButtonStyle}
                                        size="large"
                                    >
                                        <Icon type="save" theme="filled" />
                                        Bewaar team
                                    </Button>
                                )}
                            </Block>
                            <Title type={TitleType.h3}>Minicompetities</Title>
                            <Block>
                                <LeaguesList
                                    size={5}
                                    showHeader={false}
                                    leagueNameAsLink={true}
                                    isLoading={false}
                                    data={leaguesWithNumbers}
                                />
                                <Link to="/leagues">
                                    <Button style={teamActionButtonStyle} size="large">
                                        Maak / zoek een minicompetitie
                                    </Button>
                                </Link>
                            </Block>
                            <Title type={TitleType.h3}>Kalender</Title>
                            <Block>
                                <Calendar
                                    data={gameData.data.matches}
                                    size={25}
                                    showHeader={false}
                                    isLoading={gameData.isFetching}
                                />
                            </Block>
                            <div
                                className="RectangleLarge"
                                style={{ width: '300px', height: '250px', margin: '0px auto' }}
                            ></div>
                        </Col>
                    </Row>
                )) || null}
            </React.Fragment>
        );
    }
}

const WrappedComponent = AbstractTeam(TeamContainer);

export default WrappedComponent;
