/* eslint-disable react/display-name */
import {
  AbstractTeam,
  Alert,
  Block,
  Col,
  Models,
  Row,
  TableStyle,
  Title,
  TitleType,
} from '@fanarena/shared';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export interface Props {
  match: any;
}

const competitionHistory = [
  {
    competitionFeed: 'KLASM',
    seasonId: 2024,
    name: 'Wielermanager Mannen',
    short: 'Voorjaar M',
    teams: 95202,
  },
  {
    competitionFeed: 'KLASF',
    seasonId: 2024,
    name: 'Wielermanager Vrouwen',
    short: 'Voorjaar V',
    teams: 25937,
  },
  {
    competitionFeed: 'GIRO',
    seasonId: 2024,
    name: 'Giromanager',
    short: 'Giro',
    teams: 48041,
  },
  {
    competitionFeed: 'TOUR',
    seasonId: 2024,
    name: 'Tourmanager',
    short: 'Tour',
    teams: 84200,
  },
  {
    competitionFeed: 'VUEL',
    seasonId: 2024,
    name: 'Vueltamanager',
    short: 'Vuelta',
    teams: 34667,
  },
];

function TeamContainer(props: Models.AbstractTeamType & Props) {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [teams, setTeams] = useState<any[] | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const userTeams = props.user.data.history.map((team: any, index: number) => {
      const totals =
        competitionHistory.find((c) => c.competitionFeed === team.competitionFeed)?.teams || 1;
      const basePoints = (1 - team.rank / totals) * 10000;

      return {
        ...team,
        basePoints,
        id: team.teamId || `team-${index}`, // Generate a unique id if not present
      };
    });

    setTeams(userTeams);
    setLoaded(true);
  }, [props.user.data.history]);

  function getColumns(isMobileView: boolean, totalBasePoints: number) {
    const renderPoints = (record: any) =>
      record.isTotal ? null : (
        <span>
          <b>
            {record.points.toLocaleString('fr-BE', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </b>
        </span>
      );

    const renderRank = (record: any) =>
      record.isTotal ? null : (
        <span>
          <b>
            {record.rank.toLocaleString('fr-BE', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </b>
        </span>
      );

    const renderBasePoints = (record: any) =>
      record.isTotal ? (
        <span>
          <b>
            {totalBasePoints.toLocaleString('fr-BE', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </b>
        </span>
      ) : (
        <span>
          <b>
            {record.basePoints.toLocaleString('fr-BE', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </b>
        </span>
      );

    const gameColumn = {
      key: 'game',
      dataIndex: 'record.teamId',
      width: isMobileView ? '30%' : '25%',
      render: (_text: string, record: any) => {
        if (record.isTotal) return <span>Totaal</span>;
        const c = competitionHistory.find((ch) => ch.competitionFeed === record.competitionFeed);
        return <p>{isMobileView ? c?.short : c?.name}</p>;
      },
    };

    if (!isMobileView) {
      return [
        {
          ...gameColumn,
          title: 'Game',
        },
        {
          title: 'Team',
          key: 'teamName',
          dataIndex: 'record.teamId',
          width: '25%',
          render: (_text: string, record: any) => {
            if (record.isTotal) return null;
            return <p>{record.teamName}</p>;
          },
        },
        {
          key: 'points',
          title: 'Punten',
          width: '10%',
          dataIndex: 'record.teamId',
          align: 'right',
          render: (_text: string, record: any) => renderPoints(record),
        },
        {
          key: 'rank',
          title: 'Rangschikking',
          width: '10%',
          dataIndex: 'record.teamId',
          align: 'right',
          render: (_text: string, record: any) => renderRank(record),
        },
        {
          key: 'basis',
          title: 'Seizoenspunten',
          width: '10%',
          dataIndex: 'record.teamId',
          align: 'right',
          render: (_text: string, record: any) => renderBasePoints(record),
        },
      ];
    } else {
      return [
        {
          ...gameColumn,
          title: 'Game',
        },
        {
          key: 'points',
          title: 'P',
          width: '20%',
          dataIndex: 'record.teamId',
          align: 'right',
          render: (_text: string, record: any) => renderPoints(record),
        },
        {
          key: 'rank',
          title: 'R',
          width: '20%',
          dataIndex: 'record.teamId',
          align: 'right',
          render: (_text: string, record: any) => renderRank(record),
        },
        {
          key: 'basis',
          title: 'SP',
          width: '30%',
          dataIndex: 'record.teamId',
          align: 'right',
          render: (_text: string, record: any) => renderBasePoints(record),
        },
      ];
    }
  }

  if (!loaded) return null;

  const totalBasePoints = teams?.reduce((sum, t) => sum + t.basePoints, 0) || 0;
  const dataSource = teams
    ? [
        ...teams,
        {
          id: 'total',
          isTotal: true,
        },
      ]
    : [];

  const columns = getColumns(isMobile, totalBasePoints);

  return (
    <Row>
      <Col lg={{ span: 20, offset: 2 }}>
        <Row gutter={[16, 16]}>
          <Col lg={{ span: 24 }}>
            <Block>
              <Title type={TitleType.h3}>Resultaten 2024</Title>
              <TableStyle
                columns={columns}
                dataSource={dataSource}
                showHeader
                locale={{ emptyText: 'No matches found.' }}
                loading={!loaded}
                // Use optional chaining or nullish coalescing to avoid "possibly undefined"
                pagination={(teams?.length ?? 0) > 10 ? { pageSize: 10 } : false}
                rowKey="id"
                rowClassName={(_record: any, index: number) =>
                  index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'
                }
              />
            </Block>
            {isMobile && (
                <span>P = Punten R = Rangschikking SP = Seizoenspunten</span>)}
            <Block>

              <Alert
                  style={{ textAlign: 'center' }}
                  message="De seizoensranking is een regelmatigheidscriterium gebaseerd op de som van alle basispunten per spel. Deze basispunten worden als volgt berekend:
(1 – (plaats / aantal deelnemers)) × 10.000. Per spel kun je dus maximaal 10.000 punten verdienen. In 2024 organiseerden we vijf spellen, waardoor het maximum 50.000 punten bedroeg."
                  type="info"
                  showIcon
              />

            </Block>
            
          </Col>
        </Row>
        
      </Col>
    </Row>
  );
}

// Provide a display name to satisfy ESLint react/display-name
TeamContainer.displayName = 'TeamContainer';

// Wrap with AbstractTeam if needed
const WrappedComponent = AbstractTeam(TeamContainer);
export default WrappedComponent;
