import {
    Alert,
    Block,
    Button,
    Col,
    Input,
    LeaguesList,
    Redux,
    Row,
    Select,
    TeamsList,
    Title,
    TitleType,
} from '@fanarena/shared';
import { Icon } from 'antd';
import Hashids from 'hashids';
import lockr from 'lockr';
import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';

import config from './../../config';
const hashIdInstance = new Hashids(config.HASH_SALT, 12);

type Props = WithStateToProps & {
    location: any;
    match: any;
    fetchTeamLeagues: any;
};

export interface State {
    selectedLeagueInfo: any;
    selectedLeagueId: number | null;
    searchLeagueName: string;
    searchLeagueResult: any[] | null;
    createLeagueName: string;
    invitedLeagueId: number | null;
    invitedLeagueInfo: any;
    addingNewLeague: boolean;
    selectedWeekId: number;
}

class Leagues extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedLeagueInfo: {},
            selectedLeagueId: (props.match.params.leagueId && parseInt(props.match.params.leagueId)) || null,
            searchLeagueName: '',
            createLeagueName: '',
            searchLeagueResult: null,
            invitedLeagueId: null,
            invitedLeagueInfo: {},
            addingNewLeague: false,
            selectedWeekId: -1,
        };
    }

    componentDidMount() {
        if (this.state.selectedLeagueId) {
            this.loadLeagueById(this.state.selectedLeagueId);
        } else if (this.props.user.leaguesFetched && this.props.user.leagues.length) {
            this.loadDefaultLeague();
        }

        if (lockr.get('inviteCode')) {
            const invitedLeagueId = hashIdInstance.decode(lockr.get('inviteCode'))[0];
            Redux.Actions.Leagues.fetchLeagueInfo(invitedLeagueId).then(invitedLeagueInfo => {
                this.setState({ invitedLeagueId, invitedLeagueInfo });
            });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.user.leagues.length !== prevProps.user.leagues.length &&
            !this.state.selectedLeagueId &&
            this.props.user.leagues.length
        ) {
            this.loadDefaultLeague();

            if (lockr.get('inviteCode')) {
                const leagueIds = this.props.user.leagues.map((league: any) => league.id);
                const invitedLeagueId = hashIdInstance.decode(lockr.get('inviteCode'))[0];
                if (leagueIds.indexOf(invitedLeagueId) !== -1) {
                    lockr.rm('inviteCode');
                    this.setState({ invitedLeagueId: null, invitedLeagueInfo: {} });
                } else {
                    scroller.scrollTo('join-leagues', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        offset: 100,
                    });
                }
            }
        }

        if (
            prevProps.application.competition.competitionFeed !==
            this.props.application.competition.competitionFeed
          ) {
            console.log('competition switch');
            this.setState({ selectedLeagueInfo: {}, selectedLeagueId: null });
            // Don’t call fetchTeamLeagues here because the new team data isn’t ready yet.
          }
        
          // When the team info updates (after a competition feed change),
          // use the updated team.id to re-fetch team leagues and reload the default league.
          if (prevProps.user.teams !== this.props.user.teams) {
            const newTeam = this.props.user.teams && this.props.user.teams[0];
            if (newTeam) {
              this.props.fetchTeamLeagues(newTeam.id).then(() => {
                // Now that the teams are updated, load the default league.
                // if(this.props.user.leaguesFetched && this.props.user.leagues.length) { this.loadDefaultLeague(); }
              });
            }
          }
    }

    loadDefaultLeague = () => {
        const leagueId = this.props.user.leagues[0].id;
        Redux.Actions.Leagues.fetchLeagueInfo(leagueId).then(leagueInfo => {
            this.setState({
                selectedLeagueInfo: leagueInfo,
                selectedLeagueId: leagueId,
            });
        });
    };

    loadLeagueById = (id: number, weekId?: number) => {
        const loadingWeekId = weekId || this.state.selectedWeekId;

        Redux.Actions.Leagues.fetchLeagueInfo(id, loadingWeekId && loadingWeekId > 0 ? loadingWeekId : null).then(
            leagueInfo => {
                this.setState({
                    selectedLeagueInfo: leagueInfo,
                    selectedLeagueId: id,
                });
            },
        );
    };

    onLeagueSelect = (leagueId: any) => {
        this.loadLeagueById(leagueId);
    };

    onSearchLeagueNameChange = (e: any) => {
        this.setState({ searchLeagueName: e.target.value });
    };

    onNewLeagueNameChange = (e: any) => {
        this.setState({ createLeagueName: e.target.value });
    };

    onSearch = (e: any) => {
        Redux.Actions.Leagues.searchLeagues(
            this.state.searchLeagueName,
            this.props.application.competition.competitionFeed,
            this.props.application.competition.seasonId,
        ).then((searchResult: any) => {
            this.setState({ searchLeagueResult: searchResult.leagues });
        });
    };

    onLeagueCreate = (e: any) => {
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];
        this.setState({ addingNewLeague: true });

        Redux.Actions.Leagues.createLeague(
            this.state.createLeagueName,
            this.props.application.competition.competitionFeed,
            this.props.application.competition.seasonId,
            team.id,
        ).then((result: any) => {
            this.setState({
                createLeagueName: '',
                selectedLeagueId: result.league.id,
            });
            this.props.fetchTeamLeagues(team.id);
            this.loadLeagueById(result.league.id);
            this.setState({ addingNewLeague: false });
        });
    };

    onJoinLeague = (league: any) => {
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

        Redux.Actions.Leagues.joinLeague(league.id, team.id).then(joinResult => {
            this.setState({
                searchLeagueResult: null,
                searchLeagueName: '',
                selectedLeagueId: league.id,
            });
            this.props.fetchTeamLeagues(team.id);
            this.loadLeagueById(league.id);
        });
    };

    onInviteLeagueCancel = () => {
        lockr.rm('inviteCode');
        this.setState({ invitedLeagueId: null, invitedLeagueInfo: {} });
    };

    onInviteLeagueJoin = (league: any) => {
        lockr.rm('inviteCode');
        this.setState({ invitedLeagueId: null, invitedLeagueInfo: {} });
        this.onJoinLeague(league);
    };

    onTeamKick = (teamId: number, leagueId: number) => {
        if (this.state.selectedLeagueId) {
            Redux.Actions.Leagues.leaveLeague(leagueId, teamId).then(() => {
                this.loadLeagueById(leagueId);
            });
        }
    };

    onLeagueLeave = () => {
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

        if (team && team.id && this.state.selectedLeagueId) {
            Redux.Actions.Leagues.leaveLeague(this.state.selectedLeagueId, team.id).then(() => {
                this.setState({
                    searchLeagueResult: null,
                    searchLeagueName: '',
                    selectedLeagueId: null,
                });
                this.props.fetchTeamLeagues(team.id);
            });
        }
    };

    onLeagueRemove = () => {
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

        if (team && team.id && this.state.selectedLeagueId) {
            Redux.Actions.Leagues.removeLeague(this.state.selectedLeagueId).then(() => {
                this.setState({
                    searchLeagueResult: null,
                    searchLeagueName: '',
                    selectedLeagueId: null,
                });
                this.props.fetchTeamLeagues(team.id);
            });
        }
    };

    onWeekSelect = (weekId: any) => {
        this.setState({ selectedWeekId: weekId });
        const { selectedLeagueId } = this.state;
        if (selectedLeagueId) {
            this.loadLeagueById(selectedLeagueId, weekId);
        }
    };

    render() {
        const { user } = this.props;
        const {
            selectedLeagueId,
            selectedLeagueInfo,
            searchLeagueName,
            createLeagueName,
            searchLeagueResult,
            invitedLeagueInfo,
            addingNewLeague,
            selectedWeekId,
        } = this.state;
        const teams = selectedLeagueInfo?.teams || selectedLeagueInfo?.result || [];
        const isAdmin =
            selectedLeagueInfo && selectedLeagueInfo.league && selectedLeagueInfo.league.admin === user.data.id;
        const team = user && user.teams && user.teams[0];
        const joinIgnoreLeagueIds = user.leagues.map((league: any) => league.id);
        let selectedLeagueInviteUrl = '';
        const deadlineWeek = this.props.matches.info && this.props.matches.info.deadlineWeek;
        const weeks = this.props.matches.weeks.map((week: any) => {
            return {
                id: week.weekId,
                name: `${this.props.gameData.data.matches.find((m:any) => m.weekId === week.weekId)?.feedUrl}`,
            };
        });

        weeks.unshift({ id: -1, name: 'Alle koersen' });

        if (selectedLeagueId) {
            selectedLeagueInviteUrl = `https://${window.location.hostname}/join/${hashIdInstance.encode(
                selectedLeagueId,
            )}`;
        }

        return (
            <React.Fragment>
                {user.fetched && !team && <Redirect to={{ pathname: `/new` }} />}
                <Row gutter={[16, 16]}>
                    <Col md={24} sm={24} xs={24}>
                        {(!user.leagues.length && (
                            <Block>
                                <Alert
                                    style={{ textAlign: 'center' }}
                                    message="Jouw team zit nog niet in een minicompetitie. Je kan een bestaande minicompetitie zoeken of er zelf eentje aanmaken."
                                    type="info"
                                    showIcon
                                />
                            </Block>
                        )) ||
                            null}
                        {(user.leagues.length && selectedLeagueId && (
                            <React.Fragment>
                                <Title type={TitleType.h3}>Minicompetities</Title>
                                <Block>
                                <Row gutter={[16, 16]}>
                                <Col md={12} sm={24} xs={24}>
                                  
                                        <Select
                                            keyProperty={'id'}
                                            value={selectedLeagueId}
                                            style={{ width: '100%' }}
                                            notFoundContent={'You team is not part of a minicompetition yet.'}
                                            textProperty={'name'}
                                            onSelect={this.onLeagueSelect}
                                            values={user.leagues}
                                        />
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                        <Select
                                            keyProperty={'id'}
                                            style={{ width: '100%' }}
                                            value={selectedWeekId}
                                            notFoundContent={'No weeks.'}
                                            textProperty={'name'}
                                            onSelect={this.onWeekSelect}
                                            values={weeks}
                                        />
                                </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                <Col md={12} sm={24} xs={24}>
                                    {/* <Alert message="Minicompetitie rangschikking tijdelijk onbeschikbaar" type="warning" /> */}
                                        {selectedLeagueId && <TeamsList
                                            leagueId={selectedLeagueId ?? 0}
                                            weekId={selectedWeekId}
                                            data={teams}
                                            size={10}
                                            showHeader={true}
                                            deadlineWeek={deadlineWeek}
                                            adminTeamId={(team && team.id) || null}
                                            isAdmin={isAdmin}
                                            onTeamKick={(team: any) => this.onTeamKick(team.id, selectedLeagueId)}
                                        />}
                                </Col>

                                <Col md={12} sm={24} xs={24}>
                                    
                                        <Alert
                                            style={{ textAlign: 'center' }}
                                            message="Deel deze link met vrienden, familie en collega’s. Vraag ze om een team aan te maken. Daarna worden ze automatisch uitgenodigd voor deze minicompetitie."
                                            type="info"
                                            showIcon
                                        />
                                        <Row style={{ marginTop: '5px' }}>
                                            <Col md={17} sm={24} xs={24}>
                                                <Block style={{ margin: '0' }}>
                                                    <Input disabled={true} value={selectedLeagueInviteUrl} />
                                                </Block>
                                            </Col>

                                            <Col md={7} sm={24} xs={24}>
                                                <Block style={{ margin: '0' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <CopyToClipboard text={selectedLeagueInviteUrl}>
                                                            <Button style={{ marginTop: '3px' }} size="small">
                                                                <Icon type="copy" />
                                                                Kopieer uitnodigingslink
                                                            </Button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </Block>
                                            </Col>
                                        </Row>
                                    
                                        <Row style={{ marginTop: '5px', textAlign: 'center' }}>
                                            <Button
                                                style={{ margin: '3px' }}
                                                onClick={this.onLeagueLeave}
                                                type="danger"
                                                size="small"
                                            >
                                                <Icon type="logout" />
                                                Minicompetitie verlaten
                                            </Button>
                                            {(isAdmin && (
                                                <Button
                                                    style={{ margin: '3px' }}
                                                    onClick={this.onLeagueRemove}
                                                    type="danger"
                                                    size="small"
                                                >
                                                    <Icon type="delete" />
                                                    Verwijder minicompetitie
                                                </Button>
                                            )) ||
                                                null}
                                            {/* LOCK LEAGUE {(isAdmin && selectedLeagueInfo?.league?.status !== 2 && (
                                                <Button
                                                    style={{ margin: '3px' }}
                                                    onClick={(e: any) => {
                                                        alert("LOCK");
                                                    }}
                                                    type="danger"
                                                    size="small"
                                                >
                                                    <Icon type="lock" />
                                                    Sluit minicompetitie
                                                </Button>
                                            )) ||
                                                null}
                                            {(isAdmin && selectedLeagueInfo?.league?.status === 2 && (
                                                <Button
                                                    style={{ margin: '3px' }}
                                                    onClick={(e: any) => {
                                                        alert("LOCK");
                                                    }}
                                                    type="default"
                                                    size="small"
                                                >
                                                    <Icon type="lock" />
                                                    Open minicompetitie
                                                </Button>
                                            )) ||
                                                null} */}
                                        </Row>
                                </Col>
                                </Row>
                                </Block>
                            </React.Fragment>
                        )) ||
                            null}
                    </Col>

                    <Col md={12} sm={24} xs={24}>
                        <Element name="join-leagues">
                                {/* <Alert message="Minicompetities zijn tijdelijk onbeschikbaar. Deze zullen voor de Strade Bianche terug beschikbaar zijn." type="warning" /> */}
                                <Title type={TitleType.h3}>Zoek een minicompetitie</Title>
                                <Block>
                                {invitedLeagueInfo &&
                                    invitedLeagueInfo.league &&
                                    invitedLeagueInfo.league.id &&
                                    joinIgnoreLeagueIds.indexOf(invitedLeagueInfo.league.id) === -1 && (
                                        <div style={{ paddingBottom: '10px' }}>
                                            <Alert
                                                style={{ textAlign: 'center' }}
                                                message={`Wil je deelnemen aan de minicompetitie ${invitedLeagueInfo.league.name}?`}
                                                type="info"
                                                showIcon
                                            />

                                            <div style={{ textAlign: 'right' }}>
                                                <Button
                                                    onClick={this.onInviteLeagueCancel}
                                                    style={{ margin: '5px' }}
                                                    size="small"
                                                >
                                                    Neen
                                                </Button>

                                                <Button
                                                    onClick={(e: any) =>
                                                        this.onInviteLeagueJoin(invitedLeagueInfo.league)
                                                    }
                                                    style={{ margin: '5px' }}
                                                    size="small"
                                                >
                                                    JA
                                                </Button>
                                            </div>
                                        </div>
                                    )}

                                <Input
                                    onChange={this.onSearchLeagueNameChange}
                                    placeholder={'Zoek op naam'}
                                    value={searchLeagueName}
                                    maxLength={64}
                                />
                                <div style={{ textAlign: 'right' }}>
                                    <Button
                                        style={{ marginTop: '5px' }}
                                        disabled={!searchLeagueName.length}
                                        onClick={this.onSearch}
                                        size="small"
                                    >
                                        Zoek
                                    </Button>
                                </div>
                                {(searchLeagueResult && (
                                    <div style={{ marginTop: '5px' }}>
                                        <LeaguesList
                                            size={5}
                                            showHeader={false}
                                            noLeaguesMessage={'Geen minicompetities gevonden.'}
                                            onJoin={this.onJoinLeague}
                                            joinIgnoreLeagueIds={joinIgnoreLeagueIds}
                                            isLoading={false}
                                            data={searchLeagueResult.map((item, index) =>
                                                Object.assign({}, item, { recordNumber: index + 1 }),
                                            )}
                                        />
                                    </div>
                                )) ||
                                    null}
                                {(this.props.application.competition.competitionFeed === "KLASM") && <div style={{ marginTop: '5px' }}>
                                    <h4>Publieke minicompetities <Icon type="check-circle" /></h4>
                                    <LeaguesList
                                        size={5}
                                        showHeader={false}
                                        noLeaguesMessage={'Geen minicompetities gevonden.'}
                                        onJoin={this.onJoinLeague}
                                        joinIgnoreLeagueIds={joinIgnoreLeagueIds}
                                        isLoading={false}
                                        data={[
                                            //   {
                                            //     "id": 9972,
                                            //     "name": "WielerClub Wattage",
                                            //     "admin": 86254,
                                            //     "weekId": 1
                                            //   },
                                            //   {
                                            //     "id": 4113,
                                            //     "name": "De Lead-Out",
                                            //     "admin": 86260,
                                            //     "weekId": 1
                                            //   },
                                            //   {
                                            //     "id": 2009,
                                            //     "name": "Klassiekers Dankuwel",
                                            //     "admin": 92439,
                                            //     "weekId": 1
                                            //   },
                                            //   {
                                            //     "id": 13012,
                                            //     "name": "Mewa-konvooi",
                                            //     "admin": 86220,
                                            //     "weekId": 1
                                            //   }
                                            ].map((item, index) =>
                                            Object.assign({}, item, { recordNumber: index + 1 }),
                                        )}
                                    />
                                </div>}
                                {(this.props.application.competition.competitionFeed === "KLASF") && <div style={{ marginTop: '5px' }}>
                                    <h4>Publieke minicompetities <Icon type="check-circle" /></h4>
                                    <LeaguesList
                                        size={5}
                                        showHeader={false}
                                        noLeaguesMessage={'Geen minicompetities gevonden.'}
                                        onJoin={this.onJoinLeague}
                                        joinIgnoreLeagueIds={joinIgnoreLeagueIds}
                                        isLoading={false}
                                        data={[
                                            //   {
                                            //     "id": 9972,
                                            //     "name": "WielerClub Wattage",
                                            //     "admin": 86254,
                                            //     "weekId": 1
                                            //   },
                                            //   {
                                            //     "id": 4113,
                                            //     "name": "De Lead-Out",
                                            //     "admin": 86260,
                                            //     "weekId": 1
                                            //   },
                                            //   {
                                            //     "id": 2009,
                                            //     "name": "Klassiekers Dankuwel",
                                            //     "admin": 92439,
                                            //     "weekId": 1
                                            //   },
                                            //   {
                                            //     "id": 13014,
                                            //     "name": "Mewa-konvooi",
                                            //     "admin": 86220,
                                            //     "weekId": 1
                                            //   }
                                            ].map((item, index) =>
                                            Object.assign({}, item, { recordNumber: index + 1 }),
                                        )}
                                    />
                                </div>}
                            </Block>
                        </Element>
                    </Col>

                    <Col md={12} sm={24} xs={24}>
                       
                            <Title type={TitleType.h3}>Maak een minicompetitie</Title>
                            <Block>
                            <Input
                                onChange={this.onNewLeagueNameChange}
                                placeholder={'Naam'}
                                value={createLeagueName}
                                maxLength={64}
                            />

                            <div style={{ textAlign: 'right' }}>
                                <Button
                                    style={{ marginTop: '5px' }}
                                    disabled={createLeagueName.length < 3 || addingNewLeague}
                                    onClick={this.onLeagueCreate}
                                    size="small"
                                >
                                    Maak een minicompetitie
                                </Button>
                            </div>
                        </Block>
                    </Col>
                </Row>
                <div className="RectangleLarge" style={{ width: '300px', height: '250px', margin: '0px auto' }}></div>
            </React.Fragment>
        );
    }
}

export const mapDispatchToProps = {
    fetchTeamLeagues: Redux.Actions.User.fetchUserTeamLeaguesAndPersist,
};

export function mapStateToProps({ user, application, matches, gameData }: Redux.StoreState) {
    return {
        user,
        application,
        matches,
        gameData
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Leagues);
