var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import config from '../../config';
var Feeds = ['KLASM', 'KLASF'];
var LS_KEY = 'COMPETITION_FEED';
var competitions = config.COMPETITION_CONFIG;
var storedFeed = window.localStorage.getItem(LS_KEY);
var defaultFeed = Feeds.includes(storedFeed)
    ? storedFeed
    : Feeds[0];
var defaultState = {
    activeFeed: defaultFeed,
    feeds: Feeds,
    competition: competitions.find(function (comp) { return comp.competitionFeed === defaultFeed; }),
};
export function applicationReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case 'SWITCH_COMPETITION_FEED':
            window.localStorage.setItem(LS_KEY, action.feed);
            return __assign(__assign({}, state), { activeFeed: action.feed, competition: competitions.find(function (comp) { return comp.competitionFeed === action.feed; }) });
        default:
            return state;
    }
}
