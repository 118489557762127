var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { Redux } from '../../redux';
import { BasicTableStyle, PlayerListContainerStyle } from '../PlayerList/style';
import Button from '../UI/Button';
import Link from '../UI/Link';
import { isArray } from 'lodash';
var TeamsList = /** @class */ (function (_super) {
    __extends(TeamsList, _super);
    function TeamsList(props) {
        var _this = _super.call(this, props) || this;
        _this.handleTableChange = function (pagination) {
            var _a;
            _this.setState({ currentPage: pagination.current, fetchingRankings: true });
            console.log('this props', _this.props);
            Redux.Actions.Leagues.fetchLeagueInfo((_a = _this.props.leagueId) !== null && _a !== void 0 ? _a : 0, _this.props.weekId, undefined, pagination.current).then(
            //todo pagination
            function (leagueInfo) {
                console.log('leagueInfo', leagueInfo);
                _this.setState({
                    // rankingsResults: leagueInfo.result.data.map((team: any) => Object.assign({}, team.team ? team.team : team, { 
                    //     rank: (pagination.current-1)*10+team.rank 
                    // })),
                    rankingsResults: isArray(leagueInfo.result.data) ? leagueInfo.result.data.map(function (team) {
                        if (team.team) {
                            return Object.assign({}, team.team, { rank: team.rank, user: { nameVisible: team.team.name_visible, firstName: team.team.first_name, lastName: team.team.last_name } });
                        }
                        else {
                            return Object.assign({}, team, { rank: team.rank });
                        }
                    }) :
                        leagueInfo.result.data.data.map(function (team) {
                            return Object.assign({}, team.team, { rank: team.rank, user: { nameVisible: team.team.name_visible, firstName: team.team.first_name, lastName: team.team.last_name } });
                        }),
                    fetchingRankings: false,
                });
            });
        };
        // console.log('data', props.data);
        _this.state = {
            rankingsResults: isArray(_this.props.data) ? _this.props.data.map(function (team) {
                return Object.assign({}, team.team ? team.team : team, { rank: team.rank });
            }) :
                _this.props.data.data.map(function (team) {
                    return Object.assign({}, team.team, { rank: team.rank, user: { nameVisible: team.team.name_visible, firstName: team.team.first_name, lastName: team.team.last_name } });
                }),
            // selectedRankingsType: 'general',
            // searchRankingTerm: '',
            // selectedWeekId: 1,
            currentPage: 1,
            // totalRecordsPerPage: 10,
            fetchingRankings: false,
        };
        return _this;
    }
    TeamsList.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                rankingsResults: isArray(this.props.data) ? this.props.data.map(function (team) {
                    return Object.assign({}, team.team ? team.team : team, { rank: team.rank });
                }) :
                    this.props.data.data.map(function (team) {
                        return Object.assign({}, team.team, { rank: team.rank, user: { nameVisible: team.team.name_visible, firstName: team.team.first_name, lastName: team.team.last_name } });
                    }),
                // selectedRankingsType: 'general',
                // searchRankingTerm: '',
                // selectedWeekId: 1,
                currentPage: 1,
                // totalRecordsPerPage: 10,
                fetchingRankings: false,
            });
        }
    };
    TeamsList.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, data = _b.data, size = _b.size, showHeader = _b.showHeader, isLoading = _b.isLoading, isAdmin = _b.isAdmin, adminTeamId = _b.adminTeamId;
        console.log("DATA tootal", data);
        var pagination = {
            current: this.state.currentPage,
            total: (_a = data === null || data === void 0 ? void 0 : data.totalRecords) !== null && _a !== void 0 ? _a : data === null || data === void 0 ? void 0 : data.length,
            pageSize: size,
        };
        var columns = [
            {
                title: '#',
                key: 'rank',
                dataIndex: 'rank',
                width: '10%',
                render: function (_text, _record, index) {
                    return React.createElement("b", null, (pagination.current - 1) * pagination.pageSize + index + 1);
                },
            },
            {
                key: 'name',
                title: 'Naam',
                width: '50%',
                dataIndex: 'name',
                render: function (text, record) {
                    var _a, _b, _c;
                    return (React.createElement(React.Fragment, null,
                        React.createElement("b", null, _this.props.deadlineWeek !== 1 ? React.createElement(Link, { to: "/points/" + record.id }, text) : text),
                        React.createElement("span", { style: { display: 'block', fontSize: '12px' } }, ((_a = record.user) === null || _a === void 0 ? void 0 : _a.nameVisible) ? ((_b = record.user) === null || _b === void 0 ? void 0 : _b.firstName) + " " + ((_c = record.user) === null || _c === void 0 ? void 0 : _c.lastName) : null)));
                },
            },
            {
                key: 'points',
                title: 'Punten',
                width: '20%',
                dataIndex: 'points',
                render: function (text, record) {
                    return React.createElement("b", null, text ? text : record.totalPoints);
                },
            },
        ];
        if (isAdmin) {
            columns.push({
                key: 'action',
                title: '',
                width: '20%',
                dataIndex: 'action',
                render: function (_text, team) {
                    return ((adminTeamId && adminTeamId !== team.id && (React.createElement(Button, { onClick: function () { return _this.props.onTeamKick && _this.props.onTeamKick(team); }, size: "small" }, "Verwijder"))) || React.createElement("span", null));
                },
            });
        }
        return (React.createElement(PlayerListContainerStyle, null,
            React.createElement(BasicTableStyle, { columns: columns, dataSource: this.state.rankingsResults, showHeader: showHeader, locale: { emptyText: 'Deze minicompetitie heeft nog geen ploegen.' }, loading: isLoading, onChange: this.handleTableChange, pagination: pagination, rowKey: "id", rowClassName: function (_record, index) {
                    return index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even';
                } })));
    };
    return TeamsList;
}(Component));
export default TeamsList;
