import { Block, Col, Redux, Row, Title, TitleType } from '@fanarena/shared';
import parseHTML from 'html-react-parser';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Rules extends Component<WithStateToProps> {
    state = {
        text: '',
    };

    getRules() {
        Redux.Actions.User.getPage(this.props.application.competition.competitionFeed, 'rules_2025').then(
            (result: any) => {
                const body =
                    result &&
                    result[0] &&
                    result[0].translations &&
                    result[0].translations[0] &&
                    result[0].translations[0].body;
                this.setState({ text: typeof body === 'string' ? body : '' });
            },
        );
    }

    componentDidMount() {
        this.getRules();
    }

    componentDidUpdate(prevProps: any) {
        if(this.props.application.competition.competitionFeed && prevProps.application.competition.competitionFeed !== this.props.application.competition.competitionFeed) {
            this.getRules();
        }
    }

    render() {
        return (
            <Row>
                <Col lg={{ span: 20, offset: 2 }}>
                    <Row gutter={[16, 16]}>
                        <Col lg={{ span: 24 }}>
                            <Block className="rules">
                                <Title type={TitleType.h3}>Spelregels</Title>
                                <Block>{parseHTML(this.state.text)}</Block>
                            </Block>
                        </Col>
                        {/* <Col lg={{ span: 8 }}>
                            <Block>
                                <a href='https://www.garmin.com/nl-BE/'><img alt="garmin_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_garmin.jpg'/></a>
                                <a href='https://www.ridley-bikes.com/nl_BE'><img alt="ridley_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_ridley.jpg'/></a>
                            </Block>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps({ application }: Redux.StoreState) {
    return {
        application,
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, {})(Rules);
