import http from '../../lib/http';
export function FetchTeamLeagues(teamId) {
    return http.get("leagues/" + teamId, {});
}
export function FetchLeagueInfo(leagueId, weekId, externalTeamIds, pageNumber) {
    if (externalTeamIds === void 0) { externalTeamIds = []; }
    var urlString = weekId ?
        "league-redis/" + leagueId + "?&pageRecords=10&pageNumber=" + (pageNumber ? pageNumber : 1) + "&weekId=" + weekId + (externalTeamIds && externalTeamIds.length ? "&externalTeamIds=[" + externalTeamIds.join(',') + "]" : '')
        : "league/" + leagueId + "?&pageRecords=10&pageNumber=" + (pageNumber ? pageNumber : 1) + (externalTeamIds && externalTeamIds.length ? "&externalTeamIds=[" + externalTeamIds.join(',') + "]" : '');
    return http.get(urlString, {});
}
export function SearchLeagues(name, competitionFeed, seasonId) {
    return http.get("league/search", { name: name, competitionFeed: competitionFeed, seasonId: seasonId });
}
export function CreateLeague(name, competitionFeed, seasonId, teamId) {
    return http.post("league/team/" + teamId, {
        name: name,
        competitionFeed: competitionFeed,
        seasonId: seasonId,
    });
}
export function JoinLeague(leagueId, teamId) {
    return http.post("league/" + leagueId + "/join/" + teamId, {});
}
export function LeaveLeague(leagueId, teamId) {
    return http.delete("league/" + leagueId + "/" + teamId, {});
}
export function RemoveLeague(leagueId) {
    return http.delete("league/" + leagueId, {});
}
export var LeaguesActions;
(function (LeaguesActions) {
    LeaguesActions.fetchTeamLeagues = FetchTeamLeagues;
    LeaguesActions.fetchLeagueInfo = FetchLeagueInfo;
    LeaguesActions.searchLeagues = SearchLeagues;
    LeaguesActions.createLeague = CreateLeague;
    LeaguesActions.joinLeague = JoinLeague;
    LeaguesActions.leaveLeague = LeaveLeague;
    LeaguesActions.removeLeague = RemoveLeague;
})(LeaguesActions || (LeaguesActions = {}));
