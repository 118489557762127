var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '../../styles/styled';
var MatchDaySelectorStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    background: #3C3C3C;\n    border-radius: 8px;\n    text-align: center;\n    padding: 5px 0;\n\n    font-size: 20px;\n    font-weight: bold;\n\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    -webkit-user-select: none;     \n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n"], ["\n    position: relative;\n    background: #3C3C3C;\n    border-radius: 8px;\n    text-align: center;\n    padding: 5px 0;\n\n    font-size: 20px;\n    font-weight: bold;\n\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    -webkit-user-select: none;     \n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n"])));
var ButtonStyle = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: block;\n    border-radius: 40px;\n    width: 35px;\n    height: 35px;\n    cursor: pointer;\n    color: #FFF;\n    background: url(/assets/img/arrow.svg) #83FF00 center center no-repeat;\n    background-size: 20px 20px;\n    margin: 0 0 0 5px;\n    \n    &.disabled {\n        cursor: not-allowed;\n        }\n        \n    ", "\n"], ["\n    display: block;\n    border-radius: 40px;\n    width: 35px;\n    height: 35px;\n    cursor: pointer;\n    color: #FFF;\n    background: url(/assets/img/arrow.svg) #83FF00 center center no-repeat;\n    background-size: 20px 20px;\n    margin: 0 0 0 5px;\n    \n    &.disabled {\n        cursor: not-allowed;\n        }\n        \n    ",
    "\n"])), function (_a) {
    var type = _a.type;
    return type === 'next' && "\n        transform: rotate(180deg);\n        margin: 0 5px 0 0;\n    ";
});
export { MatchDaySelectorStyle, ButtonStyle };
var templateObject_1, templateObject_2;
