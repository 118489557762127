/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable react/jsx-no-target-blank */
import {
    AbstractTeam,
    Block,
    Button,
    Checkbox,
    Col,
    Helpers,
    Input,
    Models,
    PlayerList,
    PlayerType,
    Row,
    Select,
    Substitutes,
    Team,
    TeamOverview,
    Title,
    TitleType,
} from '@fanarena/shared';
// import teamBackground from '@fanarena/shared/assets/img/bg-cycling-shimano.png';
// import teamSponsor from '@fanarena/shared/assets/img/SSC_logo.jpg';
import { Icon } from 'antd';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';

interface Props {
    match: any;
}

class NewTeamContainer extends Component<Models.AbstractTeamType & Props> {
    constructor(props: Models.AbstractTeamType & Props) {
        super(props);
    }
    
    onPlaceHolderClick = (e: any) => {
        scroller.scrollTo('all-players', {
            duration: 1000,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    };

    render() {
        const { application, starting, bench, captainId, teamName, budget, savingTeamPending } = this.props;
        const startingByPositions = Helpers.startingListToPositionsList(
            starting,
            application.competition.lineupPositionRows,
        );
        const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
        const startingPicked = starting.filter((player: Models.Player) => !!player);
        const benchPicked = bench.filter((player: Models.Player) => !!player);
        const totalPlayersPicked = startingPicked.length + benchPicked.length;
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

        return (
            <React.Fragment>
                {team && team.id && <Redirect to={{ pathname: `/edit/${team.id}` }} />}
                <Row gutter={[16, 16]}>
                    <Col md={12} sm={12} xs={24}>
                        <Title type={TitleType.h3}>Selectie</Title>
                        <Block>
                        <TeamOverview
                            budget={this.props.budget}
                            totalPlayers={totalPlayersToPick}
                            totalPlayersSelected={totalPlayersPicked}
                            teamSameClubPlayersLimit={this.props.application.competition.teamSameClubPlayersLimit || 4}
                        />
                        <Team
                            widthRatio={12}
                            heightRatio={12}
                            bg={'https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/bg-lidl.jpg'}
                            bgSponsor={''}
                            assetsCdn={application.competition.assetsCdn}
                            selection={startingByPositions}
                            showPlayerValue={true}
                            playerType={PlayerType.Cycling}
                            playerBadgeColor="#000"
                            playerBadgeBgColor="#fff"
                            onPlaceholderClick={this.onPlaceHolderClick}
                            onRemove={(player: Models.Player) => this.props.removeStartingPlayer(player)}
                            playerPointsColor="#000"
                            playerPointsBgColor="#83FF00"
                        />
                        {(this.props.application.competition.benchSize > 0 && <Substitutes
                            selection={bench}
                            title="De bus"
                            bgColor="#3C3C3C"
                            playerType={PlayerType.Cycling}
                            assetsCdn={application.competition.assetsCdn}
                            showPlayerValue={true}
                            playerBadgeColor="#000"
                            playerBadgeBgColor="#fff"
                            playerPointsColor="#000"
                            playerPointsBgColor="#fff"
                            onPlaceholderClick={this.onPlaceHolderClick}
                            onRemove={(player: Models.Player) => this.props.removeBenchPlayer(player)}
                        />)}
                        <Block style={{margin: 0, padding: "10px 0"}}><a href="https://lidl.be" target="_blank"><img alt="" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/banner_lidl.jpg" /></a></Block>
                        </Block>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                        <Title type={TitleType.h3}>Team</Title>
                        <Block>
                            <Title type={TitleType.h4}>Ploegnaam</Title>
                            <Input onChange={this.props.onTeamNameChange} value={teamName} maxLength={64} />
                            <Checkbox onChange={this.props.onNameVisibleChange} style={{color: "#9FA8AD"}}>Mijn voor- en achternaam mogen zichtbaar zijn in de rangschikking.</Checkbox>
                        
                            <Title type={TitleType.h4}>{(application.competition.competitionFeed === 'KLASF') ? 'Kopvrouw' : 'Kopman'}</Title>
                            <Select
                                block
                                keyProperty={'id'}
                                value={captainId}
                                notFoundContent={'Geen starters gevonden.'}
                                textProperty={'name'}
                                onSelect={this.props.onCaptainSelect}
                                values={startingPicked}
                                placeholder={
                                    startingPicked.length ?
                                        application.competition.competitionFeed === 'KLASF' ? 'Wijs een kopvrouw aan.' : 'Wijs een kopman aan.' 
                                            : 'Selecteer eerst renners.'
                                }
                            />
                            <Button
                                onClick={this.props.onTeamSave}
                                disabled={savingTeamPending}
                                style={{ marginTop: '15px', width: '100%', maxWidth: '630px' }}
                                size="large"
                            >
                                <Icon type="save" theme="filled" />
                                Bewaar opstelling
                            </Button>
                        </Block>
                        <Title type={TitleType.h3}>Rennerslijst</Title>
<Block>
                            <Element name="all-players">
                                <PlayerList
                                    clubs={this.props.clubs.data}
                                    isLoading={this.props.players.isFetching}
                                    hidePositions={true}
                                    assetsCdn={application.competition.assetsCdn}
                                    isPickAble={this.props.isPickAble}
                                    playerType={PlayerType.Cycling}
                                    data={this.props.players.data.filter((player) => {return player.active === 1})}
                                    onPick={this.props.pickPlayer}
                                    action
                                    showHeader={false}
                                    size={10}
                                />
                            </Element>
                            <Row>
                                {/* <Col md={8} sm={24} xs={24}>
                                    <a href="https://www.kwaremont.be" target="_blank" rel="noreferrer">
                                        <img src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cta-kwaremont.jpg" alt="Kwaremont" style={{"padding": "10px"}}/>
                                    </a>
                                </Col> */}
                                <Col md={12} sm={24} xs={24}>
                                    <a href="https://www.deleadout.com" target="_blank" rel="noreferrer">
                                        <img src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cta-dlo.png" alt="De Lead-out" style={{"padding": "10px"}}/>
                                    </a>
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                    <a href="https://sporza.be/nl/sporza-podcast/kopman/" target="_blank" rel="noreferrer">
                                        <img src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cta-kopman.png" alt="Kopman Podcast" style={{"padding": "10px"}}/>
                                    </a>
                                </Col>
                            </Row>
                            </Block>
                        <div
                            className="RectangleLarge"
                            style={{ width: '300px', height: '250px', margin: '0px auto' }}
                        ></div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const WrappedComponent = AbstractTeam(NewTeamContainer);

export default WrappedComponent;
