/* eslint-disable react/jsx-no-target-blank */
import {
    AbstractTeam,
    Alert,
    Block,
    Button,
    Checkbox,
    Col,
    Helpers,
    Input,
    Models,
    PlayerList,
    PlayerType,
    Redux,
    Row,
    Select,
    Substitutes,
    Team,
    TeamOverview,
    Title,
    TitleType,
} from '@fanarena/shared';
// import teamBackground from '@fanarena/shared/assets/img/bg-cycling-shimano.png';
// import teamSponsor from '@fanarena/shared/assets/img/SSC_logo.jpg';
import { Icon, Spin } from 'antd'; // Import the Spin component from antd
import { pick } from 'lodash';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';

export interface Props {
    match: any;
    onNameVisibleChange: any;
    nameVisible: boolean;
}

export interface State {
    notFound: boolean;
    isLoading: boolean; // New state flag for loading
}

class EditTeamContainer extends Component<Models.AbstractTeamType & Props, State> {
    constructor(props: Models.AbstractTeamType & Props) {
        super(props);

        this.state = {
            notFound: false,
            isLoading: true, // Initially loading is true
        };
    }

    onPlaceHolderClick = (e: any) => {
        scroller.scrollTo('all-players', {
            duration: 1000,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    };

    componentDidMount() {
        const teamId = parseInt(this.props.match.params.id);
        const playerProps = [
            'id',
            'name',
            'short',
            'positionId',
            'clubId',
            'value',
            'ban',
            'injury',
            'form',
            'forename',
            'surname',
        ];
        const selectionProps: any[] = [];

        const urlTeam = this.props.user.teams.find(t => t.id === teamId);
        if (!urlTeam) {
            // If the team isn’t found in the URL teams, update state and stop further processing.
            this.setState({ notFound: true, isLoading: false });
            return;
        }

        Redux.Actions.Teams.get(teamId)
            .then(result => {
                const starting = result.players
                    .filter((player: any) => player.selection.positionId === 1)
                    .map((player: any) =>
                        Object.assign(
                            { inStarting: true },
                            pick(player, playerProps),
                            pick(player.selection, selectionProps),
                        ),
                    );
                const bench = result.players
                    .filter((player: any) => player.selection.positionId === 0)
                    .map((player: any) =>
                        Object.assign(
                            { inStarting: false },
                            pick(player, playerProps),
                            pick(player.selection, selectionProps),
                        ),
                    );
                const teamName = result.team.name;

                const captainId = result.players.find((player: any) => player.selection.captain === 1).id;

                const budget = result.players.reduce(
                    (acc: any, player: any) => acc - player.value,
                    this.props.application.competition.budget,
                );

                this.props.initTeamState(
                    starting,
                    bench,
                    teamName,
                    captainId,
                    budget,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    result.user.nameVisible,
                );

                // Once the team data is initialized, hide the spinner.
                this.setState({ isLoading: false });
            })
            .catch(error => {
                this.setState({ notFound: true, isLoading: false });
            });
    }
    
    componentDidUpdate() {
        const teamId = parseInt(this.props.match.params.id);
        const urlTeam = this.props.user.teams.find(t => t.id === teamId);
        if (!urlTeam) { 
            this.setState({ notFound: true });
        }
    }

    render() {
        // Render a loading spinner while data is being fetched.
        if (this.state.isLoading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Spin size="large" tip="Loading..." />
                </div>
            );
        }

        const {
            application,
            starting,
            bench,
            captainId,
            teamName,
            budget,
            matches,
            user,
            teamNameChanged,
            initializedExternally,
            onNameVisibleChange,
            nameVisible,
        } = this.props;
        const startingByPositions = Helpers.startingListToPositionsList(
            starting,
            application.competition.lineupPositionRows,
        );
        const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
        const startingPicked = starting.filter(player => !!player);
        const benchPicked = bench.filter(player => !!player);
        const totalPlayersPicked = startingPicked.length + benchPicked.length;
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

        const firstPlayingWeekPassed =
            team &&
            team.id &&
            matches &&
            matches.info &&
            matches.info.deadlineWeek &&
            matches.info.deadlineWeek > team.weekId &&
            matches.info.deadlineWeek > application.competition.competitionStartingWeek;
        const notTeamOwner = team && team.userId && user.data && team.userId !== user.data.id;

        return (
            <React.Fragment>
                {(notTeamOwner || this.state.notFound) && <Redirect to={{ pathname: `/home` }} />}
                {firstPlayingWeekPassed && <Redirect to={{ pathname: `/transfers/${team.id}` }} />}
                {(initializedExternally && (
                    <Row gutter={[16, 16]}>
                        <Col md={12} sm={24} xs={24}>
                            <Title type={TitleType.h3}>Selectie</Title>
                            <Block>
                                <Alert
                                    style={{ textAlign: 'center' }}
                                    message="Je kan onbeperkt aanpassen tot de start van de eerste koers."
                                    type="info"
                                    showIcon
                                />
                            </Block>
                            <Block>
                                <TeamOverview
                                    budget={budget}
                                    totalPlayers={totalPlayersToPick}
                                    totalPlayersSelected={totalPlayersPicked}
                                    teamSameClubPlayersLimit={this.props.application.competition.teamSameClubPlayersLimit || 4}
                                />
                                <Team
                                    widthRatio={12}
                                    heightRatio={12}
                                    bg={'https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/bg-lidl.jpg'}
                                    bgSponsor={''}
                                    selection={startingByPositions}
                                    assetsCdn={application.competition.assetsCdn}
                                    playerType={PlayerType.Cycling}
                                    playerBadgeColor="#000"
                                    playerBadgeBgColor="#fff"
                                    showPlayerValue={true}
                                    onRemove={(player: Models.Player) => this.props.removeStartingPlayer(player)}
                                    onPlaceholderClick={this.onPlaceHolderClick}
                                    playerPointsColor="#000"
                                    playerPointsBgColor="#83FF00"
                                />
                                {(this.props.application.competition.benchSize > 0 && (
                                    <Substitutes
                                        selection={bench}
                                        title="De bus"
                                        bgColor="#3C3C3C"
                                        playerType={PlayerType.Cycling}
                                        assetsCdn={application.competition.assetsCdn}
                                        onPlaceholderClick={this.onPlaceHolderClick}
                                        showPlayerValue={true}
                                        playerBadgeColor="#000"
                                        playerBadgeBgColor="#fff"
                                        playerPointsColor="#000"
                                        playerPointsBgColor="#fff"
                                        onRemove={(player: Models.Player) => this.props.removeBenchPlayer(player)}
                                    />
                                ))}
                                {team && (
                                    <Button
                                        onClick={(e: any) => this.props.onTeamEdit(team.id)}
                                        style={{ marginTop: '15px', width: '100%', maxWidth: '630px' }}
                                        size="large"
                                    >
                                        <Icon type="save" theme="filled" />
                                        Ploeg aanpassen
                                    </Button>
                                )}
                                <Block style={{ margin: 0, padding: '10px 0' }}>
                                    <a href="https://lidl.be" target="_blank" rel="noopener noreferrer">
                                        <img alt="" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/banner_lidl.jpg" />
                                    </a>
                                </Block>
                            </Block>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Title type={TitleType.h3}>Ploegnaam</Title>
                            <Block>
                                <Input onChange={this.props.onTeamNameChange} value={teamName} maxLength={64} />
                                {teamNameChanged && (
                                    <div style={{ textAlign: 'right' }}>
                                        <Button
                                            onClick={this.props.resetTeamName}
                                            style={{ margin: '5px' }}
                                            size="small"
                                        >
                                            Annuleer
                                        </Button>
                                        <Button
                                            onClick={(e: any) => this.props.onTeamNameUpdate(team.id)}
                                            style={{ margin: '5px' }}
                                            size="small"
                                        >
                                            Bewaar
                                        </Button>
                                    </div>
                                )}
                                <Checkbox
                                    onChange={onNameVisibleChange}
                                    checked={nameVisible}
                                    style={{ color: '#9FA8AD' }}
                                >
                                    Mijn voor- en achternaam mogen zichtbaar zijn in de rangschikking.
                                </Checkbox>
                            </Block>
                            <Title type={TitleType.h3}>
                                {application.competition.competitionFeed === 'KLASF' ? 'Kopvrouw' : 'Kopman'}
                            </Title>
                            <Block>
                                <Select
                                    block
                                    keyProperty={'id'}
                                    value={captainId}
                                    notFoundContent={'Geen starters gevonden.'}
                                    textProperty={'name'}
                                    onSelect={this.props.onCaptainSelect}
                                    values={startingPicked}
                                    placeholder={
                                        startingPicked.length
                                            ? application.competition.competitionFeed === 'KLASF'
                                                ? 'Wijs een kopvrouw aan.'
                                                : 'Wijs een kopman aan.'
                                            : 'Selecteer eerst renners.'
                                    }
                                />
                                {team && (
                                    <Button
                                        onClick={(e: any) => this.props.onTeamEdit(team.id)}
                                        style={{ marginTop: '15px', width: '100%', maxWidth: '630px' }}
                                        size="large"
                                    >
                                        <Icon type="save" theme="filled" />
                                        Update team
                                    </Button>
                                )}
                            </Block>
                            <Title type={TitleType.h3}>Alle Renners</Title>
                            <Block>
                                <Element name="all-players">
                                    <PlayerList
                                        clubs={this.props.clubs.data}
                                        isLoading={this.props.players.isFetching}
                                        hidePositions={true}
                                        assetsCdn={application.competition.assetsCdn}
                                        isPickAble={this.props.isPickAble}
                                        playerType={PlayerType.Cycling}
                                        data={this.props.players.data}
                                        onPick={this.props.pickPlayer}
                                        action
                                        showHeader={false}
                                        size={10}
                                    />
                                </Element>
                                <Row style={{ margin: '0' }}>
                                    <Col md={12} sm={24} xs={24}>
                                        <a href="https://www.deleadout.com" target="_blank" rel="noreferrer">
                                            <img
                                                src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cta-dlo.png"
                                                alt="De Lead-out"
                                                style={{ padding: '10px' }}
                                            />
                                        </a>
                                    </Col>
                                    <Col md={12} sm={24} xs={24}>
                                        <a href="https://sporza.be/nl/sporza-podcast/kopman/" target="_blank" rel="noreferrer">
                                            <img
                                                src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cta-kopman.png"
                                                alt="Kopman Podcast"
                                                style={{ padding: '10px' }}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </Block>
                            <div
                                className="RectangleLarge"
                                style={{ width: '300px', height: '250px', margin: '0px auto' }}
                            ></div>
                        </Col>
                    </Row>
                )) || null}
            </React.Fragment>
        );
    }
}

const WrappedComponent = AbstractTeam(EditTeamContainer);

export default WrappedComponent;
