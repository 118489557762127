import {
    BasicTableStyle,
    Block,
    Button,
    Col,
    Link,
    Models,
    PlayerList,
    PlayerPortrait,
    PlayerType,
    Redux,
    Row,
    Title,
    TitleType,
} from '@fanarena/shared';
import { Icon } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Element, scroller } from 'react-scroll';

export interface State {
    selectedPlayer: any;
    selectedPlayerStats: any;
}

type Props = WithStateToProps;

class Stats extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedPlayer: props.players.data.filter((player) => {return player.active === 1})[0] || null,
            selectedPlayerStats: [],
        };
    }

    componentDidMount() {
        if (this.props.players.data.length) {
            this.getPlayerDetails(this.props.players.data.filter((player) => {return player.active === 1})[0]);
        }
    }

    getPlayerDetails(firstPlayer: Models.Player) {
        Redux.Actions.Players.getPlayerDetails(
            this.props.application.competition.competitionFeed,
            this.props.application.competition.seasonId,
            firstPlayer.id,
        ).then((result: any) => {
            this.setState({ selectedPlayer: firstPlayer, selectedPlayerStats: result.stats || [] });
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.players.data.length !== prevProps.players.data.length && this.props.players.data.length) {
            this.getPlayerDetails(this.props.players.data.filter((player) => {return player.active === 1})[0]);
        }
    }

    setPlayer = (player: Models.Player) => {
        Redux.Actions.Players.getPlayerDetails(
            this.props.application.competition.competitionFeed,
            this.props.application.competition.seasonId,
            player.id,
        ).then((result: any) => {
            this.setState({ selectedPlayer: player, selectedPlayerStats: result.stats || [] });

            scroller.scrollTo('player-stats', {
                duration: 1000,
                delay: 100,
                smooth: true,
                offset: 0,
            });
        });
    };

    render() {
        let selectedPlayerClub = null;

        if (this.state.selectedPlayer && this.props.clubs.data) {
            selectedPlayerClub = this.props.clubs.data.find(
                (club: any) => club.id === this.state.selectedPlayer.clubId,
            );
        }

        const columns = [
            {
                key: 'weekId',
                title: 'Dag',
                dataIndex: 'weekId',
                width: '20%%',
                render: (text: string, record: any) => {
                    return <b>{record.stat.weekId}</b>;
                },
            },
            {
                key: 'race',
                title: 'Koers',
                width: '40%',
                dataIndex: 'race',
                render: (text: string, record: any) => {
                    return (
                        <Link href={record.match.info} type={'external'}>
                            {record.match.feedUrl}
                        </Link>
                    );
                },
            },
            {
                key: 'rank',
                width: '20%',
                title: 'Plts',
                dataIndex: 'rank',
                render: (text: string, record: any) => {
                    return <b>{record.stat.value}</b>;
                },
            },
            {
                key: 'Punten',
                width: '20%',
                title: 'Ptn',
                dataIndex: 'points',
                render: (text: string, record: any) => {
                    return <b>{record.stat.points}</b>;
                },
            },
        ];

        const selectedPlayerIcon = `${this.props.application.competition.assetsCdn}/jerseys/cycling/${selectedPlayerClub && selectedPlayerClub.id
            }.png`;
        return (
            <React.Fragment>
                <Row gutter={[16, 16]}>
                    <Col md={12} sm={12} xs={24}>
                    <Title type={TitleType.h3}>Alle Renners</Title>
                        <Block>
                            <PlayerList
                                clubs={this.props.clubs.data}
                                isLoading={this.props.players.isFetching}
                                hidePositions={true}
                                assetsCdn={this.props.application.competition.assetsCdn}
                                playerType={PlayerType.Cycling}
                                data={this.props.players.data.filter((player) => {return player.active === 1})}
                                onSelect={this.setPlayer}
                                action
                                showHeader={false}
                                size={10}
                            />
                            <div>
                                <a href={`https://s3.eu-west-3.amazonaws.com/sporza-fantasy-manager/files/sporza-wielermanager-${this.props.application.competition.competitionFeed}${this.props.application.competition.seasonId}-rennerslijst.xlsx`}>
                                    <Button size="large">
                                        <Icon type="file-excel" theme="filled" />
                                        Rennerslijst
                                    </Button>
                                </a>
                            </div>
                        </Block>
                    </Col>

                    <Col md={12} sm={12} xs={24}>
                        <Title type={TitleType.h3}>Stats</Title>
                        {(this.state.selectedPlayer && (
                            <Block>
                                <PlayerPortrait
                                    icon={selectedPlayerIcon}
                                    type={PlayerType.Cycling}
                                    style={{ height: '125px', width: '125px', margin: '0 auto' }}
                                    size={'120px'}
                                />
                            
                                <div style={{ textAlign: 'center' }}>
                                    <Title type={TitleType.h4}>{this.state.selectedPlayer.name}</Title>
                                    <p style={{ color: '#9FA8AD'}}>{selectedPlayerClub && selectedPlayerClub.name}</p>
                                </div>
                            
                                <BasicTableStyle
                                    columns={columns}
                                    dataSource={this.state.selectedPlayerStats}
                                    showHeader={true}
                                    locale={{ emptyText: 'Nog geen statistieken beschikbaar.' }}
                                    loading={false}
                                    pagination={{ pageSize: 10 }}
                                    rowKey={(record: any, index: number) => `record-${index + 1}`}
                                    rowClassName={(record: unknown, index: number) =>
                                        index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'
                                    }
                                />

                                <Row style={{margin: '0'}}>
                                    {/* <Col md={8} sm={24} xs={24}>
                                        <a href="https://www.kwaremont.be" target="_blank" rel="noreferrer">
                                            <img src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cta-kwaremont.jpg" alt="Kwaremont" style={{"padding": "10px"}}/>
                                        </a>
                                    </Col> */}
                                    <Col md={12} sm={24} xs={24}>
                                        <a href="https://www.deleadout.com" target="_blank" rel="noreferrer">
                                            <img src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cta-dlo.png" alt="De Lead-out" style={{"padding": "10px"}}/>
                                        </a>
                                    </Col>
                                    <Col md={12} sm={24} xs={24}>
                                        <a href="https://sporza.be/nl/sporza-podcast/kopman/" target="_blank" rel="noreferrer">
                                            <img src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/cta-kopman.png" alt="Kopman Podcast" style={{"padding": "10px"}}/>
                                        </a>
                                    </Col>
                                </Row>
                                <Row style={{margin: '0'}}>
                                    <Col md={24} sm={24} xs={24}>
                                        <a href="https://lidl.be" target="_blank" rel="noreferrer">
                                            <img src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/banner_lidl.jpg" alt="Lidl" style={{"padding": "0px"}}/>
                                        </a>
                                    </Col>
                                </Row>
                            </Block>
                        )) ||
                            null}

                        <div
                            className="RectangleLarge"
                            style={{ width: '300px', height: '250px', margin: '0px auto' }}
                        ></div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

function mapStateToProps({ application, gameData }: Redux.StoreState) {
    return {
        application,
        clubs: {
            isFetching: gameData.isFetching,
            loaded: gameData.loaded,
            data: gameData.data.clubs
        },
        players: {
            isFetching: gameData.isFetching,
            loaded: gameData.loaded,
            data: gameData.data.players,
        }
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Stats);
