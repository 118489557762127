import { Block, Col, Redux, Row, Title, TitleType } from '@fanarena/shared';
import parseHTML from 'html-react-parser';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrizesStyles, RankingPrizesStyles } from './style';

const rankingMapping: {
    [k: string]: string;
} = {
    '1': 'general',
    '2': 'week1',
    '3': 'week2',
    '4': 'week3',
};

function getImageUrl(fileName: string) {
    return `https://fanarena-admin.s3-eu-west-1.amazonaws.com/${fileName}`;
}

type PrizesProps = WithStateToProps;

type RankingPrizesProps = {
    prizes: any;
};

// class RankingPrizes extends Component<RankingPrizesProps> {
//     render() {
//         return this.props.prizes && this.props.prizes.length
//             ? this.props.prizes.map((prize: any, index: number) => {
//                   return (
//                       <RankingPrizesStyles key={`prize-${index}`}>
//                           <Row className="prize-row" style={{ margin: 0 }}>
//                               {index % 2 !== 0 ? (
//                                   <React.Fragment>
//                                       <Col md={12} xs={24} className="prize-body">
//                                           <h2>{prize.title}</h2>
//                                           {parseHTML(prize.body || '')}
//                                       </Col>
//                                       <Col md={12} xs={24}>
//                                           <img alt="" src={prize.image} className="image-prizes-widget" />
//                                       </Col>
//                                   </React.Fragment>
//                               ) : (
//                                   <React.Fragment>
//                                       <Col md={12} xs={24}>
//                                           <img alt="" src={prize.image} className="image-prizes-widget" />
//                                       </Col>
//                                       <Col md={12} xs={24} className="prize-body">
//                                           <h2>{prize.title}</h2>
//                                           {parseHTML(prize.body || '')}
//                                       </Col>
//                                   </React.Fragment>
//                               )}
//                           </Row>
//                       </RankingPrizesStyles>
//                   );
//               })
//             : null;
//     }
// }

class Prizes extends Component<PrizesProps> {
    // state = {
    //     prizesMap: {} as { general: string; week1: string; week2: string; week3: string },
    //     loaded: false,
    //     activeTab: 1,
    // };

    // componentDidMount() {
    //     Redux.Actions.User.getPrizes(this.props.application.competition.competitionFeed).then((result: any) => {
    //         const prizesList = result.map((prizeData: any) => {
    //             const translatedPrize = prizeData.translations.find(
    //                 (translation: any) => translation.languageCode === 'nl',
    //             );
    //             let imageURL = null;

    //             if (prizeData.image && prizeData.image.fileName) {
    //                 imageURL = getImageUrl(prizeData.image.fileName);
    //             }
    //             return Object.assign(
    //                 { firstRank: prizeData.firstRank, lastRank: prizeData.lastRank },
    //                 translatedPrize || {},
    //                 { sort: prizeData.sort, image: imageURL, ranking: prizeData.ranking },
    //             );
    //         });

    //         const rankingsValues = Object.keys(rankingMapping);
    //         const prizesGrouped = rankingsValues
    //             .map(rankingValue => {
    //                 const key: any = rankingMapping[rankingValue];
    //                 return {
    //                     [key]: prizesList
    //                         .filter((prizeItem: any) => prizeItem.ranking === rankingValue)
    //                         .sort((firstPrize: any, secondPrize: any) => firstPrize.sort - secondPrize.sort),
    //                 };
    //             })
    //             .reduce((acc, item) => ({ ...acc, ...item }), {});
    //         this.setState({ prizesMap: prizesGrouped, loaded: true });
    //     });
    // }

    // setActiveTab = (activeTab: number) => {
    //     this.setState({ activeTab });
    // };

    render() {
        return <PrizesStyles>
            <Title type={TitleType.h3}>Prijzen</Title>
            <Row gutter={[16, 16]}>
                <Col md={16}>
                    <Block>
                        <p>Akkoord, Wielermanager speel je vooral voor de eer en voor de strijd onder vrienden in je minicompetitie. Toch zijn er ook dit jaar enkele leuke prijzen te winnen.</p>
                        {(this.props.application.competition.competitionFeed === 'KLASM' && <>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Eindklassement - 1ste plaats</h2>
                                    Further Faster
                                    <p>De Canyon Endurace definieert zijn eigen genre, waarbij de grenzen tussen snelheid, comfort en rijplezier vervagen. Een dynamische racefiets die voor iedereen toegankelijk is.</p>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="prijs-winnaar-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_1st_overall_klasm25.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Eindklassement - 2de plaats</h2>
                                    Wil je trainen met hetzelfde materiaal als Wout of Remco? Dan heb je de Garmin Edge 840 nodig. Je conditie wordt elke dag beter dankzij deze compacte en lichte fietscomputer met touchscreen, knopbediening, Garmin Coach trainingsplannen en een autonomie van wel 42 uur. Hij helpt je minutieus voor te bereiden op jouw aankomende persoonlijke uitdagingen.
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="prijs-tweede-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_2nd_overall_klasm25.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Speeldagwinnaar</h2>
                                    Lidl, verspartner van jouw peloton

                                    <p>Lidl wordt dit voorjaar opnieuw verspartner van jouw peloton. Win een voorjaar lang gratis vers van Lidl (t.w.v. €120) en beleef onvergetelijke fietsmomenten met jouw vrienden en familie.</p>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="speeldagprijs-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_1st_week_klasm25.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Eindklassement - 3de tem 10de plaats</h2>
                                    Win een limited edition Maes-koelbox!
                                    <p>Koers kijken doe je met je maten én met Maes! Scoor een unieke Maes-koelbox, ontworpen door Studio Stoutpoep, gevuld met limited edition Maes-blikken en glazen. Handig om je pinten koel te houden, nóg beter als zitplek langs het parcours. Gewoon neerzetten, openen en genieten van een frisse Maes.</p>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="prijs-maes-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_3rd-7th_overall_klasm25.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Eindklassement - Top 10</h2>
                                    <p>Win een voucher van €100 bij Brooklyn Brands And Pants. 
                                    Brooklyn, da’s een mix van merken en heel veel broeken. Dat laatste nemen we als broekenspecialist au sérieux met een jeans assortiment om ‘u’ tegen te zeggen. Verder shop je bij Brooklyn zonder problemen sustainable én geniet je in 7 winkels en online steeds van een persoonlijke service. www.brooklyn.be</p>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="winnaars-brooklyn-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_brooklyn_klasm25.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        </>)}
                        {(this.props.application.competition.competitionFeed === 'KLASF' && <>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Eindklassement - Top 5</h2>
                                    Sporty Chic met Brooklyn
                                    <p>Brooklyn, da’s een mix van merken en heel veel broeken. Dat laatste nemen we als broekenspecialist au sérieux met een jeans assortiment om ‘u’ tegen te zeggen. Verder shop je bij Brooklyn zonder problemen sustainable én geniet je in 7 winkels en online steeds van een persoonlijke service. www.brooklyn.be</p>
                                    <ul>
                                    <li>Plek 1: 300 euro voucher</li>
                                    <li>Plek 2: 250 euro voucher</li>
                                    <li>Plek 3: 200 euro voucher</li>
                                    <li>Plek 4: 125 euro voucher</li>
                                    <li>Plek 5: 125 euro voucher</li>
                                    </ul>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="winnaars-brooklyn-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_brooklyn_klasf25.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        <Row className="prize-row" style={{ margin: '20px auto', padding: '20px', borderRadius: '5px', background: '#1E1F1D' }}>
                            <React.Fragment>
                                <Col md={12} xs={24} className="prize-body">
                                    <h2 style={{color: '#83FF00', fontWeight: 'bold'}}>Eindklassement - 6de tem 10de plaats</h2>
                                    Win een limited edition Maes-koelbox!
                                    <p>Koers kijken doe je met je maten én met Maes! Scoor een unieke Maes-koelbox, ontworpen door Studio Stoutpoep, gevuld met limited edition Maes-blikken en glazen. Handig om je pinten koel te houden, nóg beter als zitplek langs het parcours. Gewoon neerzetten, openen en genieten van een frisse Maes.</p>
                                </Col>
                                <Col md={12} xs={24}>
                                    <img alt="winnaars-maes-wielermanager" style={{padding: '20px'}} src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/prize_6th-10th_overall_klasf25.jpg" className="image-prizes-widget" />
                                </Col>
                            </React.Fragment>
                        </Row>
                        </>)}
                    </Block>
                </Col>
                <Col md={8}>
                    {(this.props.application.competition.competitionFeed === 'KLASM' && 
                    <>
                        <Block><a href='https://www.canyon.com/nl-be/'><img alt="canyon_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_canyon.jpg'/></a></Block>
                        <Block><a href='https://www.garmin.com/nl-BE/'><img alt="garmin_wielermanager_prijs" src='https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_garmin.jpg'/></a></Block>
                        <Block><a href="https://lidl.be" target="_blank" rel="wielermanager noreferrer"><img alt="lidl_wielermanager_prijs" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/partner_lidl.jpg" /></a></Block>
                    </>)}
                </Col>
            </Row>
        </PrizesStyles>
        // const blockStyle = { marginBottom: '15px', paddingTop: 0 };
        // const { activeTab } = this.state;

        // return this.state.loaded ? (
        //     <PrizesStyles>
        //         <Row>
        //             <ul className="prizes-tabs">
        //                 <li className={activeTab === 1 ? 'active' : ''} onClick={(e: any) => this.setActiveTab(1)}>
        //                     Algemene
        //                 </li>
        //                 <li className={activeTab === 2 ? 'active' : ''} onClick={(e: any) => this.setActiveTab(2)}>
        //                     Week
        //                 </li>
        //             </ul>
        //         </Row>
        //         <Row>
        //             <Col span={24}>
        //                 {activeTab === 1 ? (
        //                     <Block style={blockStyle}>
        //                         <RankingPrizes prizes={this.state.prizesMap.general} />
        //                     </Block>
        //                 ) : null}
        //                 {activeTab === 2 ? (
        //                     <React.Fragment>
        //                         <Block style={blockStyle}>
        //                             <Title type={TitleType.h3}>Week 1</Title>
        //                             <RankingPrizes prizes={this.state.prizesMap.week1} />
        //                         </Block>

        //                         <Block style={blockStyle}>
        //                             <Title type={TitleType.h3}>Week 2</Title>
        //                             <RankingPrizes prizes={this.state.prizesMap.week2} />
        //                         </Block>

        //                         <Block style={blockStyle}>
        //                             <Title type={TitleType.h3}>Week 3</Title>
        //                             <RankingPrizes prizes={this.state.prizesMap.week3} />
        //                         </Block>
        //                     </React.Fragment>
        //                 ) : null}
        //             </Col>
        //         </Row>
        //     </PrizesStyles>
        // ) : null;
    }
}

function mapStateToProps({ application }: Redux.StoreState) {
    return {
        application,
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, {})(Prizes);
